import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["cell"];

  reflectValue(value) {
    [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].forEach((possibleValue)=>this.cellTarget.classList.remove(`select-value-${possibleValue}`));

    this.cellTarget.classList.add(`select-value-${value}`);
  }

  connect() {
    this.reflectValue(this.element.getElementsByTagName("select")[0].value);
  }

  update(e) {
    console.log(e);
    this.reflectValue(e.originalTarget.value);
  }
}