// hello_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "users", "form" ];

  create(e) {
    e.preventDefault();
    const batchSize = 50;

    const userText = this.usersTarget.value;
    const endPoint = this.formTarget.action;

    const newLineSplitter = /[\n\s\r\,]/
    const nonBlankLine = (l) => l.trim().length > 0

    const users = userText.split(newLineSplitter).filter(nonBlankLine);

    let arraysOfUserTexts = [];
    for (let i=0; i<users.length; i+=batchSize) {
      arraysOfUserTexts.push(
        users.slice(i, i+batchSize).join("\n")
      );
    }

    const update = (data) => {
      const dataToAppend = arraysOfUserTexts.pop();
      const token = data.token;

      if (dataToAppend) {

        fetch(endPoint,
          {
            method: "PATCH",
            credentials: 'same-origin',
            body: JSON.stringify({users: dataToAppend}),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": "application/json;charset=UTF-8",
              "Accept":  "application/json"
            }
          }
        ).then(data=>data.json()).then(update)
      } else {
        document.location = endPoint; // request show
      }
    }

    const token = this.formTarget["authenticity_token"].value;

    fetch(endPoint,
      {
        method: "POST",
        credentials: 'same-origin',
        body: JSON.stringify({users: arraysOfUserTexts.pop()}),
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": "application/json;charset=UTF-8",
          "Accept":  "application/json"
        }
      }
    ).then(data=>data.json()).then(update)
  }
}